import "./home.scss";
import Swiper from "swiper/bundle";

/**
 * Horizontal Scroll Effect
 * - create a sticky horizontal div as wide as our content (wider than the screen) need be
 * - create a vertical div as high as our horizontal content
 * - animate horizontal content at same rate as we scroll down the vertical div
 */

// Containers
// const spaceHolder = document.querySelector(".space-holder");
// const horizontal = document.querySelector(".horizontal");

// const sticky = document.querySelector(".sticky");

// // Content & Navigation
// const cards = document.querySelectorAll(".sample-card"); // query each card
// const navContainer = document.querySelector(".promise-nav");

// // Media  Query
// const mediaQuery = window.matchMedia("(max-width: 959px)");

// // Set Height
// function setHeight() {
//     // Check if the media query is true
//     if (mediaQuery.matches) {
//         // set fixed height
//         spaceHolder.style.height = `auto`;
//     } else {
//         // set dynamic height
//         spaceHolder.style.height = `${calcDynamicHeight(horizontal)}px`;
//     }
// }

// setHeight();

// // Height of vertical div
// function calcDynamicHeight(ref) {
//     const vw = window.innerWidth;
//     const vh = window.innerHeight;
//     const objectWidth = ref.scrollWidth;
//     return objectWidth - vw + vh + 0; // 150 is the padding (in pixels) desired on the right side of the .cards container. This can be set to whatever your styles dictate
// }

// // Setup nav id & data attribute
// (function () {
//     cards.forEach((card) => {
//         // loop through cards
//         let id = card.id;
//         const offset = card.offsetLeft;
//         card.dataset.offset = offset;

//         // insert buttons
//         navContainer.insertAdjacentHTML(
//             "beforeend",
//             `<div id="scroll${id}" data-offset="${offset}" class="circle"></div>`
//         );
//     });
// })();

// // moved here as the elements hadn't been created yet!
// const circles = document.querySelectorAll(".circle");

// function removeActiveClass() {
//     circles.forEach((circle) => {
//         circle.classList.remove("active");
//     });
// }

// function onScroll() {
//     // animate horizontal div
//     horizontal.style.transform = `translateX(-${sticky.offsetTop}px)`;

//     // Loop onScroll: loop through cards
//     // when card is
//     let index = 0;

//     cards.forEach((card) => {
//         const offset = sticky.offsetTop;
//         const cardWidth = card.clientWidth;
//         const cardIndex = index;
//         const cardOrder = index + 1;
//         const fromLeft = card.dataset.offset;

//         const id = `#scroll${cardOrder}`;
//         const circle = document.querySelector(id);

//         // console.log(cardWidth, cardOrder, fromLeft);
//         console.log(
//             "card",
//             cardOrder,
//             "\n",
//             "offset",
//             offset,
//             fromLeft,
//             cardWidth
//         );

//         // Navigation: Add/remove active class
//         // circle.classList.remove('active');

//         if (offset >= fromLeft && offset <= cardWidth + fromLeft) {
//             removeActiveClass();
//             circle.classList.add("active");
//         }

//         index++;
//     });
// }

// function onWindowResize() {
//     // Recalculate spaceholder size when user resizes browser
//     // spaceHolder.style.height = `${calcDynamicHeight(horizontal)}px`;
//     setHeight();
// }

// function onClick(e) {
//     // Navigation: Add/remove active class
//     removeActiveClass();
//     e.target.classList.add("active");

//     // add data attribute
//     const offset = e.target.getAttribute("data-offset");
//     // move horizontal div to position
//     horizontal.style.transition = "all 1s";
//     horizontal.style.transform = `translateX(-${offset}px)`;
//     setTimeout(function () {
//         horizontal.style.transition = "all 0s";
//     }, 1000);
// }

// // Events
// window.addEventListener("scroll", onScroll);
// window.addEventListener("resize", onWindowResize);
// navContainer.addEventListener("click", onClick);

// /**
//  * Swiper
//  * Add carousel to mutliple areas
//  */

// // Posts (Case Studies)
//  var swiper = new Swiper(".post-swiper", {
//      slidesPerView: 1,
//      spaceBetween: 30,
//      pagination: {
//          el: ".swiper-pagination-post",
//          clickable: true,
//      },
//      navigation: {
//          nextEl: ".swiper-button-next-custom-post",
//          prevEl: ".swiper-button-prev-custom-post",
//      },
//      breakpoints: {
//          // when window width is >= 640px
//          640: {
//              slidesPerView: 3,
//              spaceBetween: 30,
//          }
//        }
//  });

// Articles
var swiper = new Swiper(".article-swiper", {
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
        el: ".swiper-pagination-article",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next-custom-post",
        prevEl: ".swiper-button-prev-custom-post",
    },
    breakpoints: {
        // when window width is >= 640px
        640: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
    },
});
